// ** Type Imports
import { PaletteMode } from '@mui/material'
import { Skin, ThemeColor } from 'src/@core/layouts/types'

const DefaultPalette = (mode: PaletteMode, skin: Skin, themeColor: ThemeColor) => {
  // ** Vars
  const whiteColor = '#FFF'
  const blackColor = '#000'
  const rgbBlackColor = '0, 0, 0'
  const rgbWhiteColor = '255, 255, 255'
  const lightColor = '54, 56, 71'
  const lightColor2 = '76, 78, 100'
  const darkColor = '234, 234, 255'
  const darkColor2 = '231, 227, 252'
  const mainColor = mode === 'light' ? lightColor : darkColor
  const mainColor2 = mode === 'light' ? lightColor2 : darkColor2

  const primaryGradient = () => {
    if (themeColor === 'primary') {
      return '#C6A7FE'
    } else if (themeColor === 'secondary') {
      return '#9C9FA4'
    } else if (themeColor === 'success') {
      return '#93DD5C'
    } else if (themeColor === 'error') {
      return '#FF8C90'
    } else if (themeColor === 'warning') {
      return '#FFCF5C'
    } else {
      return '#6ACDFF'
    }
  }

  const defaultBgColor = () => {
    if (skin === 'bordered' && mode === 'light') {
      return whiteColor
    } else if (skin === 'bordered' && mode === 'dark') {
      return '#312D4B'
    } else if (mode === 'light') {
      return '#F4F5FA'
    } else return '#000000'
  }

  return {
    customColors: {
      default: defaultBgColor(),
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      primaryGradient: primaryGradient(),
      bodyBg: mode === 'light' ? whiteColor : '#141414',
      trackBg: mode === 'light' ? '#F0F2F8' : '#474360',
      darkBg: skin === 'bordered' ? '#0F0F0F' : '#141414',
      lightBg: skin === 'bordered' ? '#F4F5FA' : whiteColor,
      tableHeaderBg: mode === 'light' ? '#F9FAFC' : '#3D3759',
      tableHeaderBg2: '#3A3E5B',
      primaryMainActive: mode === 'light' ? whiteColor : blackColor,
      primaryContrastTextActive: mode === 'light' ? `rgba(${lightColor2}, 0.87)` : whiteColor,
      chartTooltipBg: mode === 'light' ? '#F2F2F2' : '#141414',
      chartTooltipBorder: '#434548',
      chartLegendColor: '#9D9AAB',
      chartGridStroke: `rgba(${lightColor2}, 0.12)`,
      greyPrimary: mode === 'light' ? '#50515F' : '#DCDCED',
      button: mode === 'light' ? whiteColor : `rgba(${lightColor2}, 0.95)`,
      cardBg: mode === 'light' ? '#F4F5FA' : blackColor,
      cardBg2: mode === 'light' ? '#F4F5FA' : '#1C1C1C',
      cardBg3: mode === 'light' ? '#E0E0E0' : '#303030',
      marker: `rgba(${mainColor2}, 0.68)`,
      border: mode === 'light' ? `rgba(${lightColor}, 0.12)` : 'rgba(234, 242, 255, 0.22)',
      playerTextColor: mode === 'light' ? `rgba(${lightColor}, 0.87)` : whiteColor,
      playerColor: mode === 'light' ? `rgba(${lightColor2}, 0.54)` : whiteColor,
      lightGreen: '#5DB921',
      activeMenu: mode === 'light' ? `rgba(${darkColor2}, 0.52)` : `rgba(${lightColor2}, 0.52)`,
      viewFieldBg: mode === 'light' ? '#F2F2F2' : '#000000',
      viewFieldBorder: mode === 'light' ? `rgba(${lightColor2}, 0.22)` : '#434548',
      chartCandles: mode === 'light' ? '#F2F2F2' : `rgba(${lightColor2}, 0.26)`,
      rgbBlack: rgbBlackColor,
      rgbWhite: rgbWhiteColor,
      rgbMain: mode === 'light' ? rgbWhiteColor : rgbBlackColor
    },
    specified: {
      main: mode === 'light' ? blackColor : whiteColor,
      contrastText: mode === 'light' ? whiteColor : `rgba(${lightColor}, 0.87)`,
      hover: {
        main: mode === 'light' ? whiteColor : blackColor,
        contrastText: mode === 'light' ? `rgba(${lightColor}, 0.87)` : whiteColor
      }
    },
    mode: mode,
    common: {
      black: blackColor,
      dark: '#0F0F0F',
      white: whiteColor
    },
    primary: {
      light: '#A543FF',
      main: '#9000FF',
      dark: '#7700F0',
      contrastText: whiteColor
    },
    secondary: {
      light: '#EEEEEE',
      main: mode === 'light' ? '#7D7D7D' : '#CBCBCB',
      dark: '#7D7D7D',
      contrastText: whiteColor
    },
    error: {
      light: '#FF625F',
      main: '#FF4D49',
      dark: '#E04440',
      contrastText: whiteColor
    },
    warning: {
      light: '#FDBE42',
      main: '#FDB528',
      dark: '#DF9F23',
      contrastText: whiteColor
    },
    info: {
      light: '#40CDFA',
      main: '#26C6F9',
      dark: '#21AEDB',
      contrastText: whiteColor
    },
    success: {
      light: '#72E128',
      main: '#83E542',
      dark: '#64C623',
      contrastText: whiteColor
    },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#F5F5F5',
      A200: '#EEEEEE',
      A400: '#BDBDBD',
      A700: '#616161'
    },
    text: {
      primary: `rgba(${mainColor}, 0.87)`,
      secondary: `rgba(${mainColor2}, 0.68)`,
      disabled: `rgba(${mainColor2}, 0.38)`
    },
    divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === 'light' ? whiteColor : '#141414',
      default: defaultBgColor()
    },
    action: {
      active: `rgba(${mainColor2}, 0.54)`,
      hover: mode === 'light' ? 'rgba(91, 76, 100, 0.07)' : 'rgba(234, 234, 255, 0.05)',
      selected: `rgba(${mainColor2}, 0.08)`,
      disabled: `rgba(${mainColor}, 0.26)`,
      disabledBackground: `rgba(${mainColor}, 0.12)`,
      focus: `rgba(${mainColor}, 0.12)`
    }
  }
}

export default DefaultPalette
