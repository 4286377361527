// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { useMediaQuery } from '@mui/material'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Source code imports
import mediaQueries from 'src/@core/styles/libs/mediaqueries'
import { useIntl } from 'react-intl'

// ** Components
import Autocomplete from 'src/layouts/components/Autocomplete'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import ChatDropdown from 'src/@core/layouts/components/shared-components/ChatDropdown'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
  // ** Hooks
  const { formatMessage } = useIntl()
  const tabletSmallMatch = useMediaQuery(mediaQueries.tabletSmall)

  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton color='inherit' sx={{ ml: -2.75, mr: 2 }} onClick={toggleNavVisibility}>
            <Icon icon='mdi:menu' />
          </IconButton>
        ) : null}
        <Autocomplete settings={settings} />
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {!tabletSmallMatch ? (
          <Button sx={{ mr: 4 }} variant='contained' component={Link} href='/release/add'>
            {formatMessage({ id: 'upload' })}
          </Button>
        ) : (
          <>
            <IconButton color='inherit' component={Link} href='/release/add'>
              <Icon icon='mdi:upload' />
            </IconButton>
          </>
        )}
        <ChatDropdown settings={settings} />
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <NotificationDropdown settings={settings} />
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  )
}

export default AppBarContent
