// ** React Imports
import { ReactNode, ReactElement, useEffect, Fragment } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** Hooks Import
import { useAuth } from 'src/hooks/useAuth'
import useDocumentsExistChecker from 'src/hooks/useDocumentsExistChecker'

// ** Source code Imports
import AccountRegectedPage from 'src/views/pages/account-rejected'
import GlobalNotice from 'src/layouts/components/global-notice'

interface AuthGuardProps {
  children: ReactNode
  fallback: ReactElement | null
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props
  const auth = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (!router.isReady || auth.loading) return

    if (auth.user === null) {
      router.push({
        pathname: '/login',
        query: { returnUrl: router.asPath }
      })
    }
  }, [router, auth])

  useDocumentsExistChecker()

  if (auth.loading || auth.user === null) return fallback
  if (auth.user.accountStatus === 'rejected') return <AccountRegectedPage />

  return (
    <Fragment>
      {children}
      <GlobalNotice />
    </Fragment>
  )
}

export default AuthGuard
