import apiConfig from './api'

type ConfigType = {
  uploadEndpoint: string
  uppy: {
    tus: {
      enabled: boolean
      endpoint: string
      chunkSize: number
      staticUrl: string
    }
    emdUploader: {
      enabled: boolean
    }
    goldenRetreaver: {
      enabled: boolean
      serviceWorker: boolean
    }
  }
  uploaderName: 'tus' | 'emd'
}

export default {
  uploadEndpoint: `${apiConfig.apiEndpoint}/api/${apiConfig.space}/uploader`,
  uppy: {
    tus: {
      enabled: true,
      endpoint: `${process.env.NEXT_PUBLIC_UPLOADER}/fileUploads/`,
      chunkSize: 64 * 1024 * 1024,
      staticUrl: process.env.NEXT_PUBLIC_STATIC
    },
    emdUploader: {
      enabled: false
    },
    goldenRetreaver: {
      enabled: false,
      serviceWorker: false
    }
  },
  uploaderName: 'tus'
} as ConfigType
